<template>
    <div class="h-full pb-10">
        <div class="flex gap-2 items-center">
            <h3 class="text-xl font-bold">{{$route.name}}</h3>
            <a-select class="ml-auto" v-model:value="currentSrc">
                <a-select-option value="https://kue.darksidedev.net/inactive">Pick List</a-select-option>
                <a-select-option value="https://darksidedev.net:5539/inactive">Emails</a-select-option>
            </a-select>
        </div>

        <div class="content bg-white shadow rounded mt-2 p-3 h-full">
            <iframe :src="currentSrc" width="100%" height="100%"></iframe>
        </div>
    </div>
</template>

<script>
import {defineComponent} from "vue";
export default defineComponent({
    name: "Queues",
    data() {
        return {
            currentSrc: "https://kue.darksidedev.net/inactive"
        }
    }
});
</script>

<style scoped>

</style>
